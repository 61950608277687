const { replaceCityPlaceholder } = require('./str');
const {
  DOORDASH_ID,
  UBER_ID,
  UBER_EATS_ID,
} = require('../enums/geographics');

function parseJobOfferContent (offer) {
  const fields = [
    'cardOverview',
    'earnings',
    'description',
    'howToStart',
    'jobRequirementsDetails',
    'fees',
    'commonExpenses',
    'joiningBonus',
    'overview',
    'overviewHeader',
    'cardTitle',
    'structuredData',
    'seoTitle',
    'seoDescription',
    'seoKeywords',
  ];

  fields.forEach(field => {
    const offerField = offer[field];
    if (offerField && offer.city) {
      offer[field] = replaceCityPlaceholder(offerField, offer.city.name);
    }
  });

  return offer;
}

function offerIsNotExpired (offer) {
  if (!offer.validThrough) {
    return true;
  }

  const validThroughDate = new Date(offer.validThrough);
  const currentDate = new Date(Date.now());

  return validThroughDate >= currentDate;
}

function isFullTimeJobOffer (offer) {
  return offer.isMoreJob || (!!offer.feedId && !!offer.slugTitle);
}

function isPartnerWithHiddenUGC (partnerId) {
  return [
    DOORDASH_ID,
    UBER_ID,
    UBER_EATS_ID,
  ].includes(partnerId);
}

module.exports = {
  parseJobOfferContent,
  offerIsNotExpired,
  isFullTimeJobOffer,
  isPartnerWithHiddenUGC,
};
