function isRobot (userAgent) {
  if (!userAgent && (typeof navigator === 'undefined' || !navigator.userAgent)) {
    return undefined;
  }

  return /bot|crawler|spider|crawling/i.test(userAgent || navigator.userAgent);
}

function blockRobotOnHTMLAssets (html) {
  if (!isRobot()) {
    return html;
  }

  return html
    .replace(/<figure.+?<\/figure>/gi, '')
    .replace(/<img.+?https:\/\/res\.cloudinary\.com.+?\/>/gi, '');
}

module.exports = {
  isRobot,
  blockRobotOnHTMLAssets,
};
