<template>
  <div>
    <aj-loader
      :show="isJobOfferLoading"
      class="componentLoader"
    />
    <job-offer />
  </div>
</template>

<script>
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import { mapState } from 'vuex';
import { EventBus } from '@/js/helpers/utils';
import { seoTitle } from '../helpers/seo';
import { setContentGrouping } from '../helpers/analytics';
import store from '../store';

export default {
  name: 'JobOfferRoute',

  components: {
    AjLoader,
    JobOffer: () => import('@/js/views/JobOffer'),
  },

  computed: {
    ...mapState(['jobOffer']),
  },

  data () {
    return {
      isJobOfferLoading: true,
    };
  },

  created () {
    EventBus.$on('job-offer-loaded', () => {
      this.isJobOfferLoading = false;
    });
  },

  async beforeRouteEnter (to, from, next) {
    await store.dispatch('jobOffer/clearProducts');
    await store.dispatch('jobOffer/getJobOffer', to.params);
    const { jobOffer } = store.state.jobOffer;
    if (jobOffer) {
      setContentGrouping({
        index: 2,
        group: jobOffer.categories
          .map(category => category.orgSlug)
          .sort(),
      });
      next(vm => vm.setInitData(jobOffer));
    } else {
      next([]);
    }
  },

  async beforeRouteUpdate (to, from, next) {
    await store.dispatch('jobOffer/getJobOffer', to.params);
    const jobOffer = store.state.jobOffer.offer;

    if (to.path === from.path) {
      return;
    }

    if (jobOffer) {
      this.setInitData(jobOffer);
      next();
    } else {
      next([]);
    }
  },

  methods: {
    setInitData (offer) {
      if (offer.seoTitle) {
        seoTitle(offer.seoTitle);
      } else {
        seoTitle(this.translate('seo.title.jobOffer', {
          cityName: offer.city.name,
          partnerName: offer.partner.name,
        }));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .componentLoader {
    min-height: 100vh;
    position: relative;
  }
</style>
