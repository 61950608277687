import Cookies from 'js-cookie';
import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';
import store from '@/js/store';
import {
  clearContentGrouping, sendPageView, setContentGrouping, triggerStoredEvent,
} from '@/js/helpers/analytics';
import languageList from '@/js/helpers/languageList';
import { sentryCaptureException } from '@/js/helpers/sentry';
import { EventBus } from '@/js/helpers/utils';
import CityCategoryContent from '@/js/components/city-category-content';
import CityCategoryRoute from '@/js/views/CityCategoryRoute';
import CityRoute from '@/js/views/CityRoute';
import JobOfferRoute from '@/js/views/JobOfferRoute';
import MoreJobsRoute from '@/js/views/more-jobs-route';
import MoreJobsOfferRoute from '@/js/views/more-jobs-offer-route';
import MoreJobsRedirectView from '@/js/views/more-jobs-redirect-view';
import NotFound404Route from '@/js/views/NotFound404';
import PartnerRoute from '@/js/views/PartnerRoute';
import PartnerIndexRoute from '@/js/views/PartnerIndex/partner-index-route';
import RootView from '@/js/views/root-view';

import blog from './blog';
import profile from './profile';
import userDesk from './user-desk';

Vue.use(Router);

// google analytics non-trackable urls
const nontrackableRoutes = [
  'not_found',
  'not_found_catch_all',
];

// replace router's push/replace functions globally, to silence
// the rejection and make the promise resolve with an error
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch(err => err);
};

// this is all needed to properly handled languages (/ for en AND /{lang} for other languages)
const allowedLanguagePrefixes = languageList.map(lang => lang.id).filter(langId => langId !== 'en');
const pathPrefixMatch = `${location.pathname}/`.match(/^\/([a-z]{2}(-[a-z]{2})?)\//);
const pathPrefix = pathPrefixMatch && pathPrefixMatch[1] ? pathPrefixMatch[1] : '';
const currentLanguagePrefix = (allowedLanguagePrefixes.indexOf(pathPrefix) !== -1) ? pathPrefix : '';

function isPageChange (to, from) {
  let pageChanged = to.meta.hasPagination && to.name === from.name;
  if (pageChanged) {
    const params = Object.keys(to.params);

    for (let i = 0, l = params.length; i < l; i++) {
      const key = params[i];
      if (key !== 'page') {
        pageChanged = pageChanged && to.params[key] === from.params[key];
      }
    }
  }

  return pageChanged;
}

const router = new Router({
  mode: 'history',
  base: '/',
  parseQuery (query) {
    return qs.parse(query);
  },
  stringifyQuery: query => {
    const result = qs.stringify(query, { format: 'RFC1738' });
    return result ? (`?${result}`) : '';
  },
  routes: [
    {
      path: `/${currentLanguagePrefix}`,
      component: RootView,
      beforeEnter (to, from, next) {
        const redirectTo = to.query.redirect;
        if (redirectTo) {
          const { isUser } = store.getters;

          if (isUser) {
            const profileData = store.state.auth.profile.user_metadata;

            if (redirectTo === 'job_offers') {
              const citySlug = profileData.worktown_slug || store.state.city.slug;

              if (citySlug) {
                next({
                  name: 'city',
                  params: { citySlug },
                  replace: true,
                });

                return;
              }
            }

            if (redirectTo === 'job_requirements' && profileData.country) {
              next({
                path: `/job-requirements-${profileData.country}`,
                replace: true,
              });

              return;
            }
          }
        }
        next();
      },

      children: [
        {
          name: 'home',
          path: '',
          component: () => import('@/js/views/Home/home-view'),

          // Force top scrolling for home page
          meta: {
            scrollTop: true,
            // Pass `translatable: true` into params if no params are required for current route
            translatableParams: { translatable: true },
          },
        },
        {
          name: 'not_found',
          path: '404',
          component: NotFound404Route,
        },
        {
          name: 'about',
          path: 'about',
          component: () => import('@/js/views/about-view'),
        },

        {
          name: 'contact',
          path: 'contact',
          component: () => import('@/js/views/contact-view'),
        },

        {
          name: 'terms',
          path: 'terms',
          component: () => import('@/js/views/terms-view'),
        },

        {
          name: 'impressum',
          path: 'impressum',
          component: () => import('@/js/views/impressum-view'),
        },

        {
          name: 'policy',
          path: 'policy',
          component: () => import('@/js/views/policy-view'),
        },
        {
          name: 'redirect_to_partner',
          path: 'redirect/:citySlug(\[a-zA-Z0-9-]+\)/:partnerSlug(\[a-zA-Z0-9-]+\)',
          component: () => import('@/js/views/redirect-view'),
          meta: { scrollTop: true },
          props: route => {
            let clickData = {};

            if (route.query.cd) {
              try {
                clickData = JSON.parse(atob(decodeURIComponent(route.query.cd)));
              } catch (error) {
                sentryCaptureException(error);
              }
            }

            return {
              citySlug: route.params.citySlug,
              partnerSlug: route.params.partnerSlug,
              clickData,
            };
          },
        },

        {
          name: 'direct_apply_thank_you',
          path: 'direct/:leadId([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})',
          component: () => import('@/js/views/DirectApply/direct-apply-thank-you-view'),
          props: route => ({
            leadId: route.params.leadId,
          }),
        },

        ...profile,
        ...userDesk,
        blog,

        {
          name: 'partner_index',
          path: 'companies/:page(page-\[0-9]+\)?',
          component: PartnerIndexRoute,
          meta: {
            hasPagination: true,
            asyncScroll: true,
          },
        },

        {
          name: 'partner',
          path: 'company/:partnerSlug(\[a-zA-Z0-9-]+\)/:countrySlug(\[a-zA-Z0-9-]+\)?/:citySlug(\[a-zA-Z0-9-]+\)?',
          component: PartnerRoute,
          meta: {
            asyncScroll: true,
          },
        },

        {
          name: 'more_jobs',
          path: 'more-jobs/:page(page-\[0-9]+\)?',
          component: MoreJobsRoute,
          meta: {
            hasPagination: true,
            asyncScroll: true,
          },
        },

        {
          name: 'more_jobs_offer',
          path: 'more-jobs/:citySlug/:partnerSlug/:feed([a-zA-Z0-9-]+)/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}|[0-9]+-[a-zA-Z0-9-_]+)',
          component: MoreJobsOfferRoute,
        },

        // route with id param should be before route with title param
        {
          name: 'more_jobs_offer_canonical',
          path: 'more-jobs/:citySlug/:partnerSlug/:feed([a-zA-Z0-9-]+)/:title([a-z0-9-]+)',
          component: MoreJobsOfferRoute,
          beforeEnter: (to, from, next) => {
            const titlePattern = /^(?!\\d+-\\d+)[a-z0-9]+(-[a-z0-9]+)*$/;
            if (titlePattern.test(to.params.title)) {
              next(); // Allow navigation if it matches the title pattern
            }
          },
        },

        {
          name: 'more_jobs_redirect_to_partner',
          path: 'redirect/more-jobs/:citySlug/:partnerSlug/:feed([a-zA-Z0-9-]+)/:id([a-zA-Z0-9-_]+)',
          component: MoreJobsRedirectView,
          props: route => ({
            citySlug: route.params.citySlug,
            partnerSlug: route.params.partnerSlug,
            feedId: route.params.feed,
            offerId: route.params.id,
          }),
        },

        {
          name: 'city',
          path: ':citySlug(\[a-zA-Z0-9-]+\)/:page(page-\[0-9]+\)?',
          component: CityRoute,
          meta: {
            translatableParams: {
              city_id: 'city.id',
            },
            hasPagination: true,
            asyncScroll: true,
          },
        },

        {
          name: 'city_category_index',
          path: ':citySlug(\[a-zA-Z0-9-]+\)/:categorySlug(\[a-zA-Z0-9-]+\)-c',
          component: CityCategoryRoute,

          children: [
            {
              name: 'city_category',
              path: ':page(page-\[0-9]+\)?',
              component: CityCategoryContent,
              pathToRegexpOptions: true,
              meta: {
                hasPagination: true,
              },
            },
          ],
        },

        {
          name: 'city_category_old',
          path: ':citySlug(\[a-zA-Z0-9-]+\)/:categorySlug(\[a-zA-Z0-9-]+\)/cat',
          redirect: { name: 'city_category' },
        },

        {
          name: 'job_offer',
          path: ':citySlug(\[a-zA-Z0-9-]+\)/:partnerSlug(\[a-zA-Z0-9-]+\)',
          component: JobOfferRoute,
          meta: {
            translatableParams: {
              city_id: 'jobOffer.jobOffer.city.id',
              partner_id: 'jobOffer.jobOffer.partner.id',
            },
          },
        },
      ],
    },
    {
      name: 'not_found_catch_all',
      path: '*',
      component: NotFound404Route,
    },
  ],

  async scrollBehavior (to, from, savedPosition) {
    // We have to turn off automatic scroll in our application because we handle it by ourself.
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    if (to.meta.asyncScroll) {
      await new Promise(resolve => {
        EventBus.$on('scroll-ready', resolve);
      });
    }
    // check if any matched route config has meta that requires scrolling to top
    if (to.matched.some(match => match.meta.scrollTop)) {
      return { x: 0, y: 0 };
    }

    if (savedPosition) {
      // savedPosition is only available for popstate navigations.
      return savedPosition;
    }

    // do not scroll page for paginated content
    if (isPageChange(to, from)) {
      return undefined;
    }

    // do not scroll when filtering on service_category page
    if (to.name === from.name && JSON.stringify(to.params) === JSON.stringify(from.params)
      && JSON.stringify(to.query) !== JSON.stringify(from.query)) {
      return undefined;
    }

    // new navigation.
    // scroll to anchor by returning the selector
    if (to.hash) {
      return { selector: to.hash };
    }

    // else returning top scroll position
    return { x: 0, y: 0 };
  },
});

function authGuard (to, from, next) {
  if (to.query.verification_code) {
    store.commit('auth/setVerificationCode', to.query.verification_code);
  } else {
    store.commit('auth/setVerificationCode', null);
  }
  next();
}

router.beforeEach(authGuard);

router.beforeEach((to, from, next) => {
  if (to.path.match(/[A-Z]/)) {
    next(to.path.toLowerCase());
  }

  next();
});

// redirect old /en/ urls to /
router.beforeEach((to, from, next) => {
  if (to.path.match(/^\/en\//)) {
    next(to.path.slice(4));
  }

  next();
});

router.beforeEach((to, from, next) => {
  const selectedLanguage = currentLanguagePrefix || 'en';
  // set language for app
  store.dispatch('i18n/setLanguage', selectedLanguage);

  // clear messages (if not closed by user)
  // I'm not sure if this code is needed. It removes all flash messages whenever the route changes
  // store.dispatch('clearMessages');
  next();
});

// clear breadcrumbs
router.afterEach((to, from) => {
  if (from.name === to.name) {
    return;
  }

  store.dispatch('breadcrumbs/setBreadcrumbs', {});
});

router.afterEach(to => {
  if (process.env.VUE_APP_STAGE) {
    return;
  }

  let canonical = document.querySelector('link[rel="canonical"]');

  if (!canonical) {
    canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    document.head.appendChild(canonical);
  }

  if (to.name === 'partner_index') {
    canonical.setAttribute('href', `https://www.appjobs.com${to.fullPath}`);
  } else {
    canonical.setAttribute('href', `https://www.appjobs.com${to.path}`);
  }
});

router.afterEach(to => {
  const { isUser } = store.getters;
  const user = store.state.auth.profile;
  const trackableRoute = (nontrackableRoutes.indexOf(to.name) === -1);

  if (trackableRoute) {
    const userIdCookie = !!Cookies.get('userId');

    // logged in, but not userId set
    if (isUser && !userIdCookie) {
      Cookies.set('userId', user.user_id);
    }

    // logged out, but userId set
    if (!isUser && userIdCookie) {
      Cookies.remove('userId');
    }

    // trigger event from local storage
    triggerStoredEvent();
  }
});

// set content grouping for each route
router.beforeEach((to, from, next) => {
  // clear grouping first
  clearContentGrouping();

  const dataSet = {
    index: 1,
    group: to.name,
  };

  setContentGrouping(dataSet);

  next();
});

// send pageView
router.afterEach(to => {
  if (!store.getters.isUser || nontrackableRoutes.includes(to.name)) {
    return;
  }

  if (process.env.NODE_ENV === 'production') {
    sendPageView({
      url: to.path,
      type: to.name,
      referralUrl: store.getters.referralUrl,
      params: JSON.stringify(to.params),
    });
  }
});

router.afterEach((to, from) => {
  // prevent setting language list for pagination
  if (isPageChange(to, from)) {
    return;
  }

  const transParams = to.meta.translatableParams;
  store.dispatch('i18n/setListLanguage', transParams);
});

export default router;
